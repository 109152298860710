/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import headerImage from "../../images/emission_header.jpg";
import commaimg from "../../images/Quotation_mark.png";
import uparrow from "../../images/top-button.svg";
import forecasting_image_section from "../../images/forecast.webp";
import initiativeImact_section from "../../images/initiative_impact.webp";
import macc_section from "../../images/macc.webp";
import forecastIcon from "../../images/Forecast.png";
import marginal_aba_cost_icon from "../../images/Marginal_Abatement_Curve.png";
import initiative_Impact_icon from "../../images/Initiative_impact.png";
import targets from "../../images/Targets.png";
import activities from "../../images/Activities.png";
import emissionfactor from "../../images/Emission_Factors.png";
import scenario_planning_section from "../../images/scenario_planning.webp";
import scinarioIcon from "../../images/Scenario_planning.png";
import leftIcon from "../../images/Left Button.svg";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { headerImage },
      srcSet: headerImage,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};
const forecasting_image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { forecasting_image_section },
      srcSet: forecasting_image_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const scenario_planning = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { scenario_planning_section },
      srcSet: scenario_planning_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const initiativeImact = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { initiativeImact_section },
      srcSet: initiativeImact_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const macc = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { macc_section },
      srcSet: macc_section,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const comma = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { commaimg },
      srcSet: commaimg,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 600,
  height: 600,
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "carbon accounting software,  esg management software, esg data management software "
      }
      title={"WiredUp carbon accounting software | ESG management software "}
      description={
        "WiredUp’s built-in ESG data management software accurately calculates, forecasts and measures the impact of your company’s carbon emissions."
      }
    >
      <Hero
        title="Emission management"
        subheading=""
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-4 grid-cols-1 p-10px page-grid row md:w-5/6 w-auto m-auto">
        <a
          href="#Scenario_Planning"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={scinarioIcon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Scenario planning</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Develop multiple scenarios that forecast your carbon emissions
                reduction plans
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Forecasting"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={forecastIcon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Forecasting</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Visualise forecasted emission scenarios and see contribution
                from each part of your organisation
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Initiative_Impact"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={initiative_Impact_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Initiative impact</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Chart any potential emissions reduction from abatement
                initiatives against your forecasted scenarios
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Marginal_Abatement_Cost"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={marginal_aba_cost_icon}
                  alt="img"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="m-auto">
                <h4 className="h4">Marginal abatement cost</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                See the abatement potential and cost impact of each carbon
                emissions reduction initiative
              </p>
            </div>
          </div>
        </a>
      </div>
      <div id="Scenario_Planning">
        <Article
          side="right"
          id={""}
          image={scenario_planning}
          alt={"scenario-planning-software"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Reduce the administration of planning, tracking and executing your carbon emissions reduction program. Identify key areas to focus your emissions-reduction efforts by developing up to five different scenarios for fuel consumption and the associated emissions forecasts for your entire organisation. Go further and visualise emissions data at a granular level by breaking consumption down by organisation structure, activity and fuel type. ",
              heading: [],
            },
          ]}
          Articletitle="Plan scenarios with carbon accounting software "
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Forecasting">
        <Article
          side="left"
          id={""}
          image={forecasting_image}
          alt={"carbon-emission-forecast"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Forecast and analyse carbon emissions across your entire organisation to visualise the future emissions trajectory for each planned scenario. Easily filter emissions at the activity or organisational level to see how they contribute to your overall carbon emission reduction targets.",
              heading: [],
            },
          ]}
          Articletitle="Emission management and forecasting"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Initiative_Impact">
        <Article
          side="right"
          id={""}
          image={initiativeImact}
          alt={"decarbonisation-roadmap-impact"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "See the impact of each carbon emission abatement initiative over time by charting their potential or planned reductions against forecasted scenarios. Explore how different initiative groupings impact the overall decarbonisation roadmap of your organisation and discover which combinations can deliver the best long-term reductions.",
              heading: [],
            },
          ]}
          Articletitle="Measure initiative impact"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div id="Marginal_Abatement_Cost">
        <Article
          side="left"
          id={""}
          image={macc}
          alt={"marginal-abatement-cost-curve-insights"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Gain deeper insight into the cost and abatement potential of each carbon emission reduction initiative through a marginal abatement cost curve (MACC) chart. By analysing the MACC, you can calculate the trade-offs between the associated costs of each emissions reduction initiative and their abatement potential.",
              heading: [],
            },
          ]}
          Articletitle="Calculate marginal abatement cost"
          listing={[]}
          afterlisting=""
        />
      </div>
      <div className="bg-white-1 p-35px">
        <center className="mt-20px">
          <h3 className="h3">
            Explore the Initiatives module’s additional features
          </h3>
        </center>
        <div className="page-grid md:grid-cols-2 grid-cols-1">
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img src={targets} alt="img" className="w-100px h-100px"></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Emission targets</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Set organisation-wide or individual location carbon emissions
                reduction targets to stay on track and meet your decarbonisation
                roadmap milestones.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img
                src={emissionfactor}
                alt="img"
                className="w-100px h-100px"
              ></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Emission factors</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                By setting fuel and electricity types by location you can
                calculate your produced emissions to see what role each part of
                the organisation plays in your carbon emissions reduction
                efforts.
              </p>
            </div>
          </div>
          <div className="page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <img src={activities} alt="img" className="w-100px h-100px"></img>
            </div>
            <div className="m-auto">
              <h4 className="h4">Activities</h4>
            </div>
            <div className="p-30px">
              <p className="md:w-desktop-4-cols w-auto m-auto">
                Set up the key activities that contribute to emissions
                production across your organisation at a location and department
                level. Link them to your organisational structure, one or more
                planned scenarios, and set them by fuel or electricity type for
                a higher level of precision in your emissions reduction scenario
                planning.
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup-change-management-software"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
        {/* <div className="float-button-arrow bg-no-repeat bg-28px min-h-28px w-30px h-30px"></div> */}
      </button>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
